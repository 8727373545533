import React from 'react';
import {connect} from 'react-redux';
import {Grid, withStyles} from "@material-ui/core";
import Helmet from "react-helmet";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import medSchoolImage from "../images/exams/medschool.jpg";
import residentImage from "../images/exams/rezidentiat.jpg";
import policeImage from "../images/exams/police.jpg";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import * as settingsActions from '../store/settings/actions';
import * as testsActions from '../store/tests/actions';
import * as authenticationSelectors from '../store/authentication/reducer';
import CardIcon from "@material-ui/icons/Payment";
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    media: {
        [theme.breakpoints.up('sm')]: {
            height: 200,
        },
        [theme.breakpoints.up('md')]: {
            height: 350,
        },
        height: 150,
    },
    icon: {
    },
    iconPremium: {
        color: green[600],
        marginTop: theme.spacing(-1),
    },
    iconNotPremium: {
        color: amber[700],
        marginTop: theme.spacing(-1),
    },
});

class ExamSelection extends React.Component {

    selectExam = (exam) => () => {
        this.props.dispatch(settingsActions.selectExam(exam));
        this.props.dispatch(testsActions.removeLocalTests());
        this.props.history.push('/tests');
    }

    showPremium(expiration) {
        const {classes} = this.props;
        if (expiration) {
            return(
                    <Grid container>
                        <Grid item xs={2}><CardIcon className={classes.iconPremium} fontSize="large" /></Grid>
                        <Grid item xs={10}><Typography variant="body1">Premium până în {expiration}</Typography></Grid>
                    </Grid>
            );
        }
        else {
            return(
                <Grid container>
                    <Grid item xs={2}><CardIcon className={classes.iconNotPremium} fontSize="large" /></Grid>
                    <Grid item xs={10}><Typography variant="body1">Nu ai cont premium</Typography></Grid>
                </Grid>
            );
        }
    }

    render() {
        const {classes, userData} = this.props;
        let medschoolExpiration = false, policeExpiration = false;
        if (userData.subscriptionExpiration !== undefined) {
            medschoolExpiration = userData.subscriptionExpiration.medschool ? new Intl.DateTimeFormat('ro', {}).format(new Date(userData.subscriptionExpiration['medschool'] * 1000)) : false;
            policeExpiration = userData.subscriptionExpiration.medschool ? new Intl.DateTimeFormat('ro', {}).format(new Date(userData.subscriptionExpiration['police'] * 1000)) : false;
        }
        return (
            <div className={classes.root}>
                <Helmet>
                    <title>Alege Examen - Grile Examene</title>
                </Helmet>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={this.selectExam('police')}>
                                <CardMedia
                                    className={classes.media}
                                    image={policeImage}
                                    title="Politie"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Poliţie
                                    </Typography>
                                    { this.showPremium(policeExpiration) }
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={this.selectExam('medschool')}>
                                <CardMedia
                                    className={classes.media}
                                    image={medSchoolImage}
                                    title="Medicina"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Medicină - beta
                                    </Typography>
                                    { this.showPremium(medschoolExpiration) }
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={residentImage}
                                    title="Rezidentiat"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Rezidenţiat - în curând
                                    </Typography>
                                    { this.showPremium(false) }
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: authenticationSelectors.getUserData(state),
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ExamSelection));