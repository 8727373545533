export const QUESTION_COUNT_OPTIONS = [10, 20, 30, 50, 100];
export const APP_VERSION = 'v 2.0-beta2';
export const MONTH_NAMES = {
    1: 'Ianuarie',
    2: 'Februarie',
    3: 'Martie',
    4: 'Aprilie',
    5: 'Mai',
    6: 'Iunie',
    7: 'Iulie',
    8: 'August',
    9: 'Septembrie',
    10: 'Octombrie',
    11: 'Noiembrie',
    12: 'Decembrie',
}
export const STATISTICS_UNAVAILABLE = false;
export const EXAMS = {
    police: 'Poliţie',
    medschool: 'Medicină',
}