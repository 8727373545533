import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles/index';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/index';
import Drawer from '@material-ui/core/Drawer/index';
import List from '@material-ui/core/List/index';
import Divider from '@material-ui/core/Divider/index';
import IconButton from '@material-ui/core/IconButton/index';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import ListItemText from '@material-ui/core/ListItemText/index';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import VersionIcon from '@material-ui/icons/AccessTime';
import BugReportIcon from "@material-ui/icons/BugReport"
import UtilsIcon from '@material-ui/icons/EventNote';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import Badge from "@material-ui/core/Badge";
import {APP_VERSION} from "../Constants";
import Tooltip from "@material-ui/core/Tooltip";
import {SwapHorizontalCircle} from "@material-ui/icons";
import LinkIconButton from "../linkComponents/LinkIconButton";

const drawerWidth = 200;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    bottom: {
        bottom: 0,
        position: 'absolute',
    },
});

class MiniDrawer extends React.Component {

    clickMenu = () => {
        this.props.handleDrawerClose();
    };

    render() {
        const { classes, theme, unfinishedTest } = this.props;
        // Show new badge for statistics item in menu.
        const utilsNew = Date.now() < Date.parse("16 July 2020");
        return (
            <div className={classes.root}>
                <Drawer
                    variant={isWidthUp('sm', this.props.width) ? "permanent" : "persistent"}
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: this.props.open,
                        [classes.drawerClose]: !this.props.open,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.props.open,
                            [classes.drawerClose]: !this.props.open,
                        }),
                    }}
                    open={this.props.open}
                    onClose={this.props.handleDrawerClose}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.props.handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    {this.props.isAuthenticated &&
                    <List onClick={this.clickMenu}>
                        <ListItem button component={Link} to="/tests" key="tests">
                            <Tooltip title="Teste" placement="right">
                                <ListItemIcon>
                                    <Badge color="primary" variant="dot" invisible={!unfinishedTest}>
                                        <AssignmentIcon/>
                                    </Badge>
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Teste"/>
                        </ListItem>
                        <ListItem button component={Link} to="/lessons" key="lessons">
                            <Tooltip title="Lecţii" placement="right">
                                <ListItemIcon><LocalLibraryIcon/></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Lecţii"/>
                        </ListItem>
                        <ListItem button component={Link} to="/statistics" key="statistics">
                            <Tooltip title="Statistci" placement="right">
                                <ListItemIcon><AssessmentIcon/></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Statistci"/>
                        </ListItem>
                        <ListItem button component={Link} to="/bugs" key="bugs">
                            <Tooltip title="Bug-uri" placement="right">
                                <ListItemIcon><BugReportIcon/></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Bug-uri"/>
                        </ListItem>
                    </List>
                    }
                    {!this.props.isAuthenticated &&
                    <ListItem button component={Link} to="/homepage" key="homepage">
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary="Prima Pagină"/>
                    </ListItem>
                    }
                    <ListItem button component={Link} to="/utils" key="utils">
                        <Tooltip title="Utile" placement="right">
                            <ListItemIcon>
                                <Badge color="primary" badgeContent={"Nou"} invisible={!utilsNew}>
                                    <UtilsIcon/>
                                </Badge>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Utile"/>
                    </ListItem>
                    <Divider />
                    <List onClick={this.clickMenu}>
                        {this.props.isAuthenticated &&
                        <ListItem button component={Link} to="/settings" key="settings">
                            <Tooltip title="Setări" placement="right">
                                <ListItemIcon><SettingsIcon/></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Setări"/>
                        </ListItem>
                        }
                        {this.props.isAuthenticated &&
                        <ListItem button component={Link} to="/exam" key="exam">
                            <Tooltip title="Alege Examen" placement="right">
                                <ListItemIcon><SwapHorizontalCircle /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Alege Examen"/>
                        </ListItem>
                        }
                        <ListItem button component={Link} to="/contact" key="contact">
                            <Tooltip title="Contact" placement="right">
                                <ListItemIcon><ContactSupportIcon /></ListItemIcon>
                            </Tooltip>
                            <ListItemText primary="Contact" />
                        </ListItem>
                    </List>
                    <List className={classes.bottom}>
                        <ListItem key={'Version'}>
                            <ListItemIcon>
                                <VersionIcon/>
                            </ListItemIcon>
                            <ListItemText primary={APP_VERSION}/>
                        </ListItem>
                    </List>
                </Drawer>
            </div>
        );
    }
}

MiniDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(MiniDrawer));